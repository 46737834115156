export const environment = {
  production: false,
  uri: 'https://sandbox.eu.zuora.com/apps/PublicHostedPageLite.do',
  paymentGateways:
  {
    paymentGateway_tmna: 'TMNA Servco Telematics-Chase',
    paymentGateway_tci: 'TCI Telematics Chase',
    paymentGateway_tdpr: 'TDPR - Telematics - Chase',
    paymentGateway_servco: 'TMNA Servco Telematics-Chase',
    paymentGateway_tmex: 'TMEX - Telematics - Test',
  },
  paymentPageIds:
  {
    tmna_servco:
    {
      t_cc_paymentPageId: '8adcd9eb795f4001017968c9c60b2b52',
      t_ach_paymentPageId: '8adc8f99795f2ea4017968cf63381182',
      l_cc_paymentPageId: '8adc8f99795f2ea4017968d6e959145d',
      l_ach_paymentPageId: '8adcd9eb795f4001017968da4ff9305a'
    },
    tdpr:
    {
      t_cc_paymentPageId: '8adcd9eb795f4001017968f7430d3ba4',
      l_cc_paymentPageId: '8adcd9eb795f4001017968f49d6d3b1b'
    },
    tci:
    {
      t_cc_paymentPageId: '8adcd9eb795f4001017968de0d35313d',
      l_cc_paymentPageId: '8adc9dee795f2ea0017968e13513216a'
    },
    tmex: {
      t_cc_paymentPageId: '8adce42179d4d5310179d860f6177082',
      l_cc_paymentPageId: '8adcd9eb784550640178478f859a52bc'
    }
  }
};
