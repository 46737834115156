<div class="zuora">
  <div *ngIf="true && !!language">
    <div *ngIf="false" class="logo">
      <a [routerLink]="" [skipLocationChange]='true' [class.toyota-img]="isToyota" [class.lexus-img]="isLexus"
        class="logo-img"></a>
    </div>
    <div class="zuora__label" [class.lexus__font]="isLexus">
      <span>{{getTranslation(paymentType ==='cc'? 'zuora.CC-label': 'zuora.ACH-label')}}</span>
    </div>
  </div>
  <div class="zuora_payment_container">
    <div id="zuora_payment" class="zuora_payment" style="width: 100%;"></div>
  </div>
  <span *ngIf="showerrormsg" class="zuora__error">{{getTranslation('zuora.error')}}</span>
  <div *ngIf="true && !!language">
    <div class="consents" *ngIf="showDefaultPayment">
      <mat-checkbox color="accent" (change)=setDefaultPay($event.checked)>
      </mat-checkbox>
      <p class="consents-p" [class.lexus__font]="isLexus">
        {{getTranslation('zuora.default-payment')}}
      </p>
    </div>
    <div class="consents">
      <mat-checkbox color="accent" (change)=verifyEvent($event.checked)>
      </mat-checkbox>
      <p class="consents-p" [class.lexus__font]="isLexus">
        {{getTranslationStrings('one')}}<span class="consents__link"
          (click)="redirectTerms()">{{getTranslationStrings('two')}}</span>{{getTranslationStrings('three')}}
      </p>
    </div>
    <div class="zuora__actions">
      <button mat-button class="custom-active-button" [class.lexus__button]="isLexusApp" [class.subaru__button]="isSubaruApp"
        [class.custom-disabled-button]=" !verify || !iframeLoaded" [disabled]=" !verify|| !iframeLoaded"
        (click)="submit()">
        <span [class.custom-disabled-button]=" !verify|| !iframeLoaded">{{getTranslation('zuora.button')}}</span>
      </button>
    </div>
  </div>
</div>
