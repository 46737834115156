export const environment = {
  production: false,
  uri: 'https://sandbox.eu.zuora.com/apps/PublicHostedPageLite.do',
  paymentGateways:
  {
    paymentGateway_tmna: 'TMNA Servco Telematics-Chase',
    paymentGateway_tci: 'TCI Telematics Chase',
    paymentGateway_tdpr: 'TDPR - Telematics - Chase',
    paymentGateway_servco: 'TMNA Servco Telematics-Chase',
    paymentGateway_tmex: 'TMEX - Telematics - Test',
  },
  paymentPageIds:
  {
    tmna_servco:
    {
      t_cc_paymentPageId: '8adc91178949452201896044d6465974',
      t_ach_paymentPageId: '8adcb13d8949451501896044c7e14b75',
      l_cc_paymentPageId: '8adcd5868949537901896044bb3d3641',
      l_ach_paymentPageId: '8adceff28949538701896044ae7f3830'
    },
    tdpr:
    {
      t_cc_paymentPageId: '8adc936589494520018960443b7657be',
      l_cc_paymentPageId: '8adcb13d89494515018960442ea84b3d'
    },
    tci:
    {
      t_cc_paymentPageId: '8adcb13d894945150189604414df4b21',
      l_cc_paymentPageId: '8adcd586894953790189604407e135d2'
    },
    tmex: {
      t_cc_paymentPageId: '8adce42179d4d5310179d860f6177082',
      l_cc_paymentPageId: '8adcd9eb784550640178478f859a52bc'
    }
  }
};
