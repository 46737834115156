import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { environment } from "../../environments/environment";
import { links } from "../ctp/TermsAndPolicy";
import { translations } from "../ctp/i18n/translations";
import { SpinnerRef } from "../common/components/spinner/overlay-ref.service";
import { SpinnerService } from "../common/components/spinner/overlay-spinner.service";
declare function sendEvent(event, data): any;
declare function setDefaultPayment(value): any;
declare function redirectTerms(url): any;
declare let Z: any;
const urlParams = new URLSearchParams(location.search);
@Component({
  selector: "app-zuora",
  templateUrl: "./zuora.component.html",
  styleUrls: ["./zuora.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ZuoraComponent implements OnInit {
  /**
   * @property to store brand
   **/
  public brand: string;
  /**
   * @property to store paymentType
   **/
  public paymentType: string;
  /**
   * @property to store language
   **/
  public language: string;
  /**
   * @property to store isToyota
   **/
  public isToyota: boolean;
  /**
   * @property to store isLexus
   **/
  public isLexus: boolean;
  /**
   * @property to store isLexusapp
   **/
  public isLexusApp: boolean;
  /**
   * @property to store flag if it is SubaruApp
   **/
  public isSubaruApp: boolean;
  /**
   * @property to store verify
   **/
  public verify: boolean;
  /**
   * @property to store showDefaultPayment
   **/
  public showDefaultPayment: boolean;
  /**
   * @property to store region
   **/
  public region: string;
  /**
   * @property to store isWebView
   **/
  public isWebView: boolean;
  /**
   * @property to store ios
   **/
  public ios: boolean;
  /**
   * @property to store appBrand
   **/
  public appBrand: string;
  /**
   *@property Reference to the spinner service so it can be open or close in any feature of the screen
   **/
  public spinnerRef: SpinnerRef;
  /**
   *@property to store showerrormsg
   **/
  public showerrormsg: boolean;
  constructor(public spinner: SpinnerService) {}
  /**
   * @function iframeLoaded Return if iframe loaded
   **/
  get iframeLoaded(): boolean {
    return document.getElementById("z_hppm_iframe").style.display === "block";
  }
  ngOnInit() {
    this.defaults();
    // redirect route without refresh
    // window.history.pushState({ id: '100' }, '', '/zuora');
    this.log("loaded defaults+ request: " + window.location.href);
    if (this.isWebView) {
      this.log("webview checked: true");
      this.loadHostedPage();
    } else {
      this.loadHostedPage();
      this.log("webview checked: false. Sending event to mobile..");
      sendEvent("failed", "Not viewing in webview");
    }
  }
  /**
   * @function log logs
   **/
  public log(log: string) {
    console.log(log);
  }
  /**
   * @function checkWebview checks if it is a webview
   **/
  private checkWebview(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    this.ios =
      /iphone|ipod|ipad/.test(userAgent) ||
      (window.navigator.platform === "MacIntel" &&
        window.navigator.maxTouchPoints > 1);
    return this.ios
      ? !safari
        ? true
        : false
      : userAgent.includes("wv")
      ? true
      : false;
  }

  /**
   * @function defaults defaults the values
   **/
  defaults(): void {
    this.region =
      urlParams.get("region") === "CA"
        ? "tci"
        : urlParams.get("region") === "HI"
        ? "servco"
        : urlParams.get("region") === "PR"
        ? "tdpr"
        : urlParams.get("region") === "MX"
        ? "tmex"
        : "tmna";
    this.setLanguage(this.region, urlParams.get("language"));
    this.brand = urlParams.get("brand").toLowerCase();
    this.appBrand = !!urlParams.get("appBrand")
      ? urlParams.get("appBrand").toLowerCase()
      : "";
    this.paymentType = urlParams.get("paymentType").toLowerCase();
    this.showDefaultPayment = urlParams.get("showDefaultPayment") === "true";
    this.isToyota = this.brand === "t" || this.brand === "s";
    this.isLexus = this.brand === "l";
    this.isLexusApp = this.appBrand === "l";
    this.isSubaruApp = this.appBrand === "s";
    this.isWebView = this.checkWebview();
  }
  /**
   * @function submit submits the iframe
   **/
  public submit(): void {
    this.showerrormsg = false;
    this.spinnerRef = this.spinner.open();
    Z.submit();
  }

  /**
   * @function verifyEvent verify toggle of form
   **/
  public verifyEvent(value: boolean): void {
    this.verify = value;
  }
  /**
   * @function setDefaultPay set default pay
   **/
  public setDefaultPay(value: boolean): void {
    setDefaultPayment(value);
  }
  /**
   * @function loadHostedPage load hosted page
   **/
  private loadHostedPage() {
    const accountId = urlParams.get("accountId");
    const params = {
      tenantId: urlParams.get("tenantId"),
      id: environment.paymentPageIds[
        this.region === "tmna" || this.region === "servco"
          ? "tmna_servco"
          : this.region
      ][`${this.isToyota ? "t" : "l"}_${this.paymentType}_paymentPageId`],
      token: urlParams.get("token"),
      signature: atob(urlParams.get("signature")),
      style: "inline",
      key: atob(urlParams.get("key")),
      submitEnabled: "true",
      locale: this.language,
      param_supportedTypes: this.getcreditCardType(),
      url: environment.uri,
      paymentGateway:
        environment.paymentGateways[`paymentGateway_${this.region}`],
      field_currency:
        this.region === "tci" ? "CAD" : this.region === "tmex" ? "MXN" : "USD",
    };
    Object.assign(params, !!accountId ? { field_accountId: accountId } : {});
    const prepopulateFields = {
      creditCardCountry: this.getZuoraCountry(),
    };
    // sendEvent('showSpinner', true);
    this.log("Params Constructed");
    Z.renderWithErrorHandler(
      params,
      prepopulateFields,
      this.callback.bind(this),
      this.clientErrorMessageCallback.bind(this)
    );
    this.log("Render Zuora Call");
    // this.setIframeStyles();
  }
  /**
   * @function setIframeStyles setIframeStyles after loading (checks every second if iframe is loaded) and clears after it detects
   **/
  public setIframeStyles(): void {
    const interval = setInterval(() => {
      if (
        !!document.getElementById("z_hppm_iframe") &&
        document.getElementById("z_hppm_iframe").style.minHeight !== "5em"
      ) {
        document.getElementById("z_hppm_iframe").style.minHeight = "5em";
        clearInterval(interval);
      }
    }, 1000);
  }
  /**
   * @function clientErrorMessageCallback iframe callbacks
   **/
  private clientErrorMessageCallback(key, code, message) {
    if (!!this.spinnerRef) {
      this.spinnerRef.close();
    }
    this.log(
      "Client error callback received. No action required." +
        key +
        code +
        message
    );
    let isServerError = false;
    let errorMessage = message;
    if (!!key && key.toLowerCase() === "error") {
      if (!!code && code.toLowerCase() === "unknown") {
        if (this.checkSystemError(message)) {
          isServerError = true;
          errorMessage = this.getTranslation("zuora.error");
        }
      }
    }
    Z.sendErrorMessageToHpm(key, errorMessage);
    if (isServerError) {
      window.scrollTo(0, 0);
    }
    return;
  }
  /**
   * @function checkSystemError checks for error codes
   **/
  public checkSystemError(message: string): boolean {
    let serverError = false;
    const errorcodes = [
      "[Attempt_Exceed_Limitation]",
      "[BusinessValidationError]",
      "[Decryption_Error]",
      "[GatewayTransactionError]",
      "[GeneralSystemError]",
      "[Invalid_PaymentGateway]",
      "[Invalid_Request_Method]",
      "[Invalid_Security]",
      "[ReCaptcha_Validation_Failed]",
      "[Validate_Dynamic_Params_Failed]",
      "[ThreeDs2_Authentication_Exception]",
    ];
    errorcodes.forEach((errorCode) => {
      if (
        !!message &&
        message.toLowerCase().includes(errorCode.toLowerCase())
      ) {
        serverError = true;
      }
    });
    return serverError;
  }
  /**
   * @function getZuoraCountry returns 3 digit country for zuora
   **/
  public getZuoraCountry(): string {
    switch (this.region) {
      case "tci":
        return "CAN";
      case "tdpr":
        return "PRI";
      case "servco":
        return "USA";
      case "tmna":
        return "USA";
      case "tmex":
        return "MEX";
      default:
        return "USA";
    }
  }
  /**
   * @function creditCardType get card type based on vin region
   **/
  public getcreditCardType(): string {
    switch (this.region) {
      case "tci":
        return "Visa,MasterCard,AmericanExpress";
      case "tdpr":
        return "Visa,MasterCard,Discover";
      default:
        return "Visa,MasterCard,Discover,AmericanExpress";
    }
  }
  /**
   * @function setLanguage sets the language
   */
  public setLanguage(region: string, language: string) {
    switch (region) {
      case "tci":
        this.language = "fr" === language ? "fr" : "en";
        break;
      case "tdpr":
        this.language = "es" === language ? "es" : "en";
        break;
      case "tmex":
        this.language = "es" === language ? "es" : "en";
        break;
      default:
        this.language = "en";
        break;
    }
  }
  /**
   * @function callback
   **/
  private callback(response) {
    if (!!this.spinnerRef) {
      this.spinnerRef.close();
    }
    this.log("General error callback received" + JSON.stringify(response));
    if (this.checkSystemError(`[${response.errorCode}]`)) {
      if (
        !!document.getElementById("z_hppm_iframe") &&
        document.getElementById("z_hppm_iframe").style.minHeight === "5em"
      ) {
        document.getElementById("z_hppm_iframe").style.minHeight =
          "0em !important";
        this.showerrormsg = true;
      }
    }
    if (response.success) {
      sendEvent("success", response.refId);
    } else {
      sendEvent(
        "failed",
        "errorcode=" +
          response.errorCode +
          ", errorMessage=" +
          response.errorMessage
      );
    }
  }
  /**
   * @function redirect to terms of use
   **/
  public redirectTerms(): void {
    let redirectLink: string =
      links[this.isToyota ? "toyota" : "lexus"][this.region][this.language];
    if (
      this.ios &&
      redirectLink ===
        "https://www.toyota.ca/servicesconnectés-conditionsdutilisation"
    ) {
      redirectLink =
        "https://s3.amazonaws.com/toyota.site.toyota-v5/tci-prod/toyota/media/pdf/Toyota_Terms_of_Use_FR.pdf";
    }
    this.log("redirect button clicked");
    if (!redirectLink) {
      redirectLink =
        links[this.isToyota ? "toyota" : "lexus"][this.region]["en"];
    }
    sendEvent(
      "redirect",
      !!redirectLink
        ? redirectLink
        : `invalid params. Brand: ${this.brand}, region: ${this.region},language: ${this.language} `
    );
    // this.log('sent event to mobile');
  }
  /**
   * @function getTranslationStrings returns the translation string
   **/
  public getTranslationStrings(join: "one" | "two" | "three"): string {
    return this.getTranslation(
      `zuora.${
        this.region === "tci" ? "tci" : this.region === "tdpr" ? "tdpr" : "tmna"
      }.consent-${this.paymentType === "cc" ? "cc" : "ach"}-${join}`
    );
  }
  /**
   * @function getTranslation fetches the vaue from the pattern
   **/
  public getTranslation(pattern: string): string {
    return this.getSubproperty(
      [pattern],
      translations[`${this.language}_translation`]
    );
  }
  /**
   * @function getSubproperty function to show nested properties
   */
  public getSubproperty(pathList, obj): string {
    const propertyMap = pathList.map((path) => {
      const properties = Array.isArray(path) ? path : path.split(".");
      const propertyReducer = properties.reduce(
        (prev, curr) => prev && prev[curr],
        obj
      );
      return propertyReducer;
    });
    return propertyMap.filter((property) => property)[0] || "";
  }
}
